.main-banner {
    position: relative;
    z-index: 99;
    height: 100vh;
    overflow: hidden;
    padding-top: 126px;

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .main-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h2 {
            margin-top: 28px;
            color: #592A0A;
            text-align: center;
            text-shadow: 3px 3px 0px #BA6A34;
            font-family: "Gochi Hand", cursive;
            font-size: 110px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
        }

        p {
            color: #7C5031;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 40px;
        }

        .coming-soon {
            border-radius: 10px;
            background: #A54300;
            box-shadow: 4px 4px 0px 0px #603213;
            padding: 14px 40px;
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

@media (max-width:600px) {
    .main-banner .main-content h2 {
        font-size: 42px;
    }

    .main-banner .main-content p {
        font-size: 20px;
        max-width: 284px;
        width: 100%;
        margin: 0 auto;
        margin-top: 11px;
        margin-bottom: 15px;
    }

    .main-banner .main-content img {
        max-width: 253px;
    }
}