@import url('https://fonts.googleapis.com/css2?family=Gaegu:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');






html {
  scroll-behavior: smooth;
}

body {
  background: #F6E3D2;
  min-height: 100vh;
  font-family: "Gaegu", sans-serif;
  
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}



.cursor-pointer {
  cursor: pointer;
}


iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}


.custom-container {
  width: 100%;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
}

@media (min-width: 1600px) and (min-height: 900px) {
  .custom-container {
    width: 100%;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
  }
}


@media (max-width:600px){
  .custom-container{
    padding-left: 15px;
    padding-right: 15px;
  }
}



